import axios from "axios";
import { CONFIG } from './constants/backendUrl'

const instance = axios.create({
    baseURL: CONFIG['apiUrl']
});

export const accumuluationApi = axios.create({
    baseURL: CONFIG['accumulationApiUrl']
});

export const accumulationReservApi = axios.create({
    baseURL: CONFIG['accumuluationReserveApiUrl']
});

export const webCrmApi = axios.create({
    baseURL: CONFIG['webCrmUrl']
});

export const biApi = axios.create({
    baseURL: CONFIG['biBack']
});

export default instance;