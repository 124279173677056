import {
    CHANGE_DEPARTMENT,
    CHANGE_EMPLOYEE,
    CHANGE_NUMBER,
    CHANGE_PAGINATION, CHANGE_REGION,
    CHANGE_STATUS, CLEAR_INTERVAL, FIRST_CALENDAR_DAY_IN_RANGE, FORGET_ME, GET_CLIENT_NAME,
    GET_COUNT_AMOUNT, GET_CURRENT_APPLICATION_DATA, GET_HASH_OF_THE_LAST_APPLICATION, GET_LAST_APPLICATION,
    GET_TEN_APPLICATIONS, INIT_APPLICATION_STATE,
    INIT_FILTERS,
    INPUT_FILTER_DATE_FROM,
    INPUT_FILTER_DATE_TO,
    IS_FILTER_DATE_ACTIVE,
    SAVE_HASH,
    SET_USER_AVITIM_ID,
    SET_TRIAL_INFO,
    SAVE_ID, SECOND_CALENDAR_DAY_IN_RANGE,
    SET_ACTIVE_FILTERS,
    SET_ACCESS_CLIENT,
    SET_ACTIVE_PAGE, SET_INTERVAL, SET_CLIENT_PHONE, SET_CONTINUEWORK, SET_ADD_FILE_MODAL, SET_APPLICATION_HASH, GET_LAST_APPLICATION_FULL_DATA, SET_LAST_APPLICATION_BACK, SET_FILTERS, SET_RETURNING_APPLICATION_DATA, SET_RETURNING_APPLICATION_ID
} from "./applicationsActionTypes";
import axios, { accumulationReservApi, accumuluationApi, biApi, webCrmApi } from "../../axiosApi";
import axiosOriginal from 'axios';
import { push } from "connected-react-router";
import { CONFIG } from "../../constants/backendUrl";
import { setExcelAccess } from "../GeneralReducer/generalActions";
import { showNotification } from "../../utils/norifications";
import { getRemainingTime } from "../../utils/functions/getRemainingTime";
import { hashToUUID } from "../../utils/functions/hash1c";

export const getTenApplicationsSuccess = (value) => ({ type: GET_TEN_APPLICATIONS, value });
export const getCountAmount = (value) => ({ type: GET_COUNT_AMOUNT, value });
export const inputFilterDateFrom = (value) => ({ type: INPUT_FILTER_DATE_FROM, value });
export const inputFilterDateTo = (value) => ({ type: INPUT_FILTER_DATE_TO, value });
export const setActiveFilters = (value) => ({ type: SET_ACTIVE_FILTERS, value });
export const setLastApplicationIsBack = (value) => ({ type: SET_LAST_APPLICATION_BACK, value });
export const changePagination = (value) => ({ type: CHANGE_PAGINATION, value });
export const setApplicationHash = (value) => ({ type: SET_APPLICATION_HASH, value })
export const changeStatus = (value) => ({ type: CHANGE_STATUS, value });
export const changeDepartment = (value) => ({ type: CHANGE_DEPARTMENT, value });
export const changeEmployee = (value) => ({ type: CHANGE_EMPLOYEE, value });
export const changeRegion = (value) => ({ type: CHANGE_REGION, value });
export const changeNumber = (value) => ({ type: CHANGE_NUMBER, value });
export const initFilters = () => ({ type: INIT_FILTERS });
export const setFilters = () => ({ type: SET_FILTERS });
export const setActivePage = (value) => ({ type: SET_ACTIVE_PAGE, value });
export const setLastApplicationFullData = (value) => ({ type: GET_LAST_APPLICATION_FULL_DATA, value });
export const saveHash = (value) => ({ type: SAVE_HASH, value });
export const saveId = (value) => ({ type: SAVE_ID, value });

export const isFilterDateActive = (value) => ({ type: IS_FILTER_DATE_ACTIVE, value });

export const setFirstCalendarDay = (value) => ({ type: FIRST_CALENDAR_DAY_IN_RANGE, value });
export const setSecondCalendarDay = (value) => ({ type: SECOND_CALENDAR_DAY_IN_RANGE, value });

export const getHashOfTheLastApplication = (value) => ({ type: GET_HASH_OF_THE_LAST_APPLICATION, value });

export const getCurrentApplicationDataSuccess = (value) => ({ type: GET_CURRENT_APPLICATION_DATA, value });

export const setMyInterval = (value) => ({ type: SET_INTERVAL, value });
export const clearMyInterval = () => ({ type: CLEAR_INTERVAL });

export const getClientNameSuccess = (value) => ({ type: GET_CLIENT_NAME, value });
export const initApplicationState = () => ({ type: INIT_APPLICATION_STATE })
export const setClientAccess = (value) => ({ type: SET_ACCESS_CLIENT, value })

export const setTrialInfo = (value) => ({ type: SET_TRIAL_INFO, value })
export const setUserAvitimID = (value) => ({ type: SET_USER_AVITIM_ID, value })
export const setContinueWork = (value) => ({ type: SET_CONTINUEWORK, value })
export const setAddFileModal = (value) => ({ type: SET_ADD_FILE_MODAL, value })
export const forgetMe = () => ({ type: FORGET_ME })
export const getLastApplicationSuccess = (value) => ({ type: GET_LAST_APPLICATION, value });

export const setReturningApplicationData = (value) => ({ type: SET_RETURNING_APPLICATION_DATA, value });
export const setReturningApplicationId = (value) => ({ type: SET_RETURNING_APPLICATION_ID, value });

export const getTenApplications = ({ data, userHash }) => {
    return async dispatch => {
        try {
            await axiosOriginal.post(`${CONFIG['biBack']}/api/asista_app_history/getData`, data, {
                headers: {
                    Authorization: `Bearer ${userHash}`
                }
            })
                .then(response => {

                    dispatch(getTenApplicationsSuccess(response.data.data.events));
                    dispatch(getCountAmount(response.data.data.total));
                });
        } catch (err) {
            dispatch(getTenApplicationsSuccess([]));
            console.log(err);
        }
    }
}

export const getExcellFile = ({ data, userHash }) => {
    return async dispatch => {
        try {
            const res = await axios.post(`${CONFIG['biBack']}/api/asista_app_history/exportExcel`, data, {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${userHash}`
                }
            })

            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Отчет ${data.filter.date.from} - ${data.filter.date.to}.xlsx`;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            dispatch(setExcelAccess(false))
        } catch (err) {
            console.log(err);
        }
    }
}
export const getLastApplication = (id) => {
    return async dispatch => {
        try {
            const resp = await biApi.get(`/api/asista/applications/last/${id}`)
            dispatch(getLastApplicationSuccess(resp.data));
        } catch (err) {
            console.log(err);
        }
    }
}
export const getReturningApplicationData = (uuid) => {
    return async dispatch => {
        try {
            const response = await biApi.get(`/api/asista/applications/get/${uuid}`)
            dispatch(setReturningApplicationData(response.data));
        } catch (err) {
            console.log(err);
        }
    }
}
export const getLastApplicationFullData = (uuid) => {
    return async dispatch => {
        try {
            const response = await biApi.get(`/api/asista/applications/get/${uuid}`)
            dispatch(setLastApplicationFullData(response.data));
            dispatch(setReturningApplicationId(uuid));
            dispatch(setReturningApplicationData(response.data));
        } catch (err) {
            console.log(err);
        }
    }
}

export const postNewApplication = (data, id, trial) => {
    return async dispatch => {
        try {
            if (trial === false) {
                const resp = await accumuluationApi.post('/tasks/create-from-asista', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (resp.data.error === 'Сервис временно недоступен') {
                    return showNotification('info', { message: 'Сервис временно недоступен, но мы сохранили вашу заявку и обработаем ее в ближайшее время 😇' })
                }

                // dispatch(getHashOfTheLastApplication(resp.data.eventID))
                dispatch(push(`/application/${id}/${resp.data.eventID}`));
            } else {
                const responseTrial = await axios.post(`/trial/itsupport`, data);
                dispatch(getHashOfTheLastApplication(responseTrial.data.eventID))
                dispatch(push(`/${id}/trial/${responseTrial.data.eventID}`))
            }

        } catch (err) {
            try {
                if (process.env.REACT_APP_ACCUMULATION_RESERVE_API_URL) {
                    await accumulationReservApi.post('reserv.php', data, { headers: { 'Content-Type': 'multipart/form-data' } })
                }
            }
            catch (e) {
                console.log('error reserv: ', e)
            }
            finally {
                return showNotification('info', { message: 'Сервис временно недоступен, но мы сохранили вашу заявку и обработаем ее в ближайшее время 😇' })
            }
        }
    }
}

export const postToContinueWork = (data) => {
    return async dispatch => {
        try {
            await axios.post(`/resume_work/company`, data);
        } catch (err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}


export const postNewInvisionApplication = (data, id, trial) => {
    return async dispatch => {
        try {
            if (trial === false) {
                const response = await axios.post('/create_task', data)
                dispatch(getHashOfTheLastApplication(response.data.eventID))
                dispatch(push(`/application/${id}/${response.data.eventID}`));
            } else {
                const responseTrial = await axios.post(`/trial/itsupport`, data);
                dispatch(getHashOfTheLastApplication(responseTrial.data.eventID))
                dispatch(push(`/${id}/trialinvision/${responseTrial.data.eventID}`))
            }

        } catch (err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}
export const getCurrentApplicationData = (uuid) => {
    return async dispatch => {
        try {
            if (!uuid) return
            const response = await biApi.get(`/api/asista/applications/get/${uuid}`)
            await dispatch(getCurrentApplicationDataSuccess(response.data));
        } catch (err) {
            dispatch(push('/error'))
            console.log(err)
        }
    }
}

export const getCurrentTrialApplicationData = (uuid) => {
    return async dispatch => {

        const response = await biApi.get(`/api/asista/applications/get/${uuid}`)
        await dispatch(getCurrentApplicationDataSuccess(response.data));

    }
}

export const addDetailsToApplicationInProcess = (data) => {
    return async () => {
        try {
            await webCrmApi.post('/asista/comments/append', data)
        } catch (err) {
            console.log(err)
        }
    }
}


export const setApplicationBackInProgress = (hash) => {
    return async (dispatch) => {
        try {
            await webCrmApi.post(`/tasks/return/${hashToUUID(hash)}`);
        } catch (err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}

export const leaveTheRate = (data) => {
    return async () => {
        try {
            const myIp = await axios.get('https://api64.ipify.org?format=json')
            const ip = myIp.data.ip
            await webCrmApi.post('/asista/reviews/set_rating', { ...data, ip })
        } catch (err) {
            console.log(err);
        }
    }
}

export const getClientName = (device_id) => {
    return async dispatch => {
        try {
            const access_response = await webCrmApi.get(`/asista/devices/info/${device_id}`)
            const name_response = await biApi.get(`/api/asista/auth/contact_person/username/${device_id}`)

            await dispatch(getClientNameSuccess({
                cua_access_management: access_response.data.cua_access_management,
                name: name_response?.data?.name || access_response?.data?.name || '',
            }));
        } catch (err) {
            console.log(err);
        }
    }
}


export const getClientCompaniesAccess = (tel) => {
    return async dispatch => {
        // if(tel[0]==="+"){
        //     tel=tel.substring(1)
        // }
        const response = await axios.get(`/prelogin/${tel}`);

        const bodyData = response.data;
        await dispatch(setClientAccess(bodyData))
    }
}

export const getTrialInfo = (tel) => {
    return async dispatch => {
        const reqBody = {
            phone: tel,
            company: "itsupport"
        }
        const response = await axios.post(`/trial/get`, reqBody);
        const bodyData = {
            avitimTrial: response.data.avitim,
            invisionTrial: response.data.invision,
            itsupportTrial: response.data.itsupport
        }
        const continueWorkData = {
            avitim: response.data.continueWorkReq.avitim,
            invision: response.data.continueWorkReq.invision,
            itsupport: response.data.continueWorkReq.itsupport,
        }
        await dispatch(setTrialInfo(bodyData))
        await dispatch(setContinueWork(continueWorkData))
    }
}

export const postTrialInfo = (data) => {
    return async dispatch => {
        const response = await axios.post(`/trial/update`, data);
        const bodyData = response.data;

    }
}

export const getUserAvitimID = (tel) => {
    return async dispatch => {
        try {
            // if(tel[0]==="+"){
            //     tel = tel.substring(1);
            // }
            const response = await axios.get(`/trial/get/device_id/avitim/${tel}`);
            await dispatch(setUserAvitimID(response.data.device_id))
        } catch (e) {
            console.log(e);
        }

    }
}

export const createAccessOpenTask = (data, id) => {
    return async dispatch => {
        try {
            const resp = await accumuluationApi.post('/hr/add', data)
            // await dispatch(getHashOfTheLastApplication(response.data.eventID))
            await dispatch(push(`/application/${id}/${resp.data.eventID}`))
        } catch (e) {
            showNotification('info', { message: 'Сервис временно недоступен, но мы сохранили вашу заявку и обработаем ее в ближайшее время 😇' })
        }
    }
}

export const changeAccessTask = (data, id) => {
    return async dispatch => {
        try {
            const resp = await accumuluationApi.post('/hr/move', data)
            // await dispatch(getHashOfTheLastApplication(response.data.eventID))
            await dispatch(push(`/application/${id}/${resp.data.eventID}`))
        } catch (e) {
            return showNotification('info', { message: 'Сервис временно недоступен, но мы сохранили вашу заявку и обработаем ее в ближайшее время 😇' })
        }
    }
}
export const closeAccessTask = (data, id) => {
    return async dispatch => {
        try {
            const resp = await accumuluationApi.post('/hr/remove', data)
            // await dispatch(getHashOfTheLastApplication(response.data.eventID))
            await dispatch(push(`/application/${id}/${resp.data.eventID}`))
        } catch (e) {
            return showNotification('info', { message: 'Сервис временно недоступен, но мы сохранили вашу заявку и обработаем ее в ближайшее время 😇' })
        }
    }
}