import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import Logo from "../../Components/ApplicationPageComponents/Logo/Logo";
import './MainPage.css';
import {ITSupport} from "../../Components/MainPageComponents/ITSupport/ITSupport";
import {AVITIM} from "../../Components/MainPageComponents/AVITIM/AVITIM";
import {INVISION} from "../../Components/MainPageComponents/INVISION/INVISION";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {prelogin} from "../../Store/UsersReducer/usersActions";
import Trials from "../../Components/MainPageComponents/Trials/Trials";
import {ITSGContact} from "../../constants/Contact/ITSGContact/ITSGContact";
import { ReactComponent as AsistaLogo} from "../../assets/logos/asista__not_white.svg"
import { CONFIG } from "../../constants/backendUrl";
import { push } from "connected-react-router";
import { getClientCompaniesAccess, getClientName, getTrialInfo, getUserAvitimID, postToContinueWork, saveId, setContinueWork } from "../../Store/ApplicationsReducer/applicationsActions";
import { sendYMAnalytics } from "../../utils/metrika";
import { VCC } from "../../Components/MainPageComponents/VCC/VCC";
import { getVksAvailability } from "../../Store/VksReducer/vksActions";
import TITLE, { ICONS } from "../../Routes/TITLE";
const MainPage = (props) => {

    const id = props.match.params.id;
    const dispatch = useDispatch();
    const continueWorkReq = useSelector(state=>state.applications.continueWork)
    const clientAccess = useSelector(state=>state.applications.clientAccess)
    const avitimId = useSelector(state=>state.applications.userAvitimID)
    const trialInfo = useSelector(state=>state.applications.trialInfo)
    const phone = useSelector(state => state.applications.clientPhone)
    const hasVks = useSelector(state=>state.vks.hasVks)
    const [sentRequestToContinueWork, setSentRequestToContinueWork] = useState(
        {
            invision: continueWorkReq?.invision,
            avitim:continueWorkReq?.avitim,
            itsupport:continueWorkReq?.itsupport
        }
    )
    const [avitimContinue, setAvitimContinue] = useState(false)
    const [invisionContinue, setInvisionContinue] = useState(false)
    const openStyle = {
        'opacity': 1,
        'backdropFilter': 'blur(2px)',
        'animation': 'openModal linear .25s'
    }

    const [infoModal, setInfoModal] = useState(false);
    const [continueModal, setContinueModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const modalHandler = () => {
        setInfoModal(!infoModal);
    }

    const closeModals = ()=>{
        setInfoModal(false);
        setContinueModal(false);
        setConfirmModal(false);
        setAvitimContinue(false);
        setInvisionContinue(false)
    }
    
    const continueModalHandler = (company) => {

        if(company==="avitim" && (!continueWorkReq.avitim || !sentRequestToContinueWork.avitim)){
            setAvitimContinue(true)
            setContinueModal(true);
        }
        if(company==="invision" && (!continueWorkReq.invision || !sentRequestToContinueWork.invision)){
            setInvisionContinue(true)
            setContinueModal(true);
        }
        if((company==="invision" && (continueWorkReq.invision || sentRequestToContinueWork.invision)) 
        || (company==="avitim" && (continueWorkReq.avitim || sentRequestToContinueWork.avitim))){
            setConfirmModal(true)
        }
        
        
    }
    useEffect(()=>{
        dispatch(saveId(id))
    }, [dispatch])
    useEffect(() => {
        dispatch(getClientName(id))
        dispatch(getVksAvailability({id: id}))
        // Отправить запрос на метрику (Основной модуль)
        sendYMAnalytics('asista')       
        
    }, [dispatch, id])

    useEffect(()=>{
        dispatch(getTrialInfo(phone))
        dispatch(getClientCompaniesAccess(phone));
        dispatch(getUserAvitimID(phone));
    }, [dispatch, phone])

    const postApplicationToContinue = async () =>{
    
        if(avitimContinue){
            const data = {
                id: id,
                fromCompany: "itsupport",
                toCompany: "avitim" ,
                phone: phone ,
            }
            setContinueWork({...continueWorkReq, avitim: true})
            setSentRequestToContinueWork({...sentRequestToContinueWork, avitim: true})
            dispatch(postToContinueWork(data))
            setConfirmModal(true);
        }
        
        if(invisionContinue){
            const data = {
                id: id,
                fromCompany: "itsupport",
                toCompany: "invision" ,
                phone: phone ,
            }
            setContinueWork({...continueWorkReq, invision: true})
            setSentRequestToContinueWork({...sentRequestToContinueWork, invision: true})
            dispatch(postToContinueWork(data))
            setConfirmModal(true);
        }
        
    }
    
    const center = (
        <div className="MainPage-body">
            <div className="MainPage-body_main">
                <div className="MainPage-body-header">
                    <AsistaLogo/>
                </div>
                {/* <div className="MainPage-body-content">
                    <h1><p> Выберите сервисную компанию</p>
                        для решения актуальной задачи </h1>
                </div> */}
                <div className="MainPage__row">
                    <div className="MainPage_company"><ITSupport clientAccess={clientAccess} supportID = {id} trialInfo={trialInfo}/></div>
                    <div className="MainPage_company"><AVITIM modalHandler={()=>continueModalHandler("avitim")} clientAccess={clientAccess} id = {id} trialInfo={trialInfo} avitimID = {avitimId}/></div>
                </div>
                <div className="MainPage__row">
                    <div className="MainPage_company"><INVISION modalHandler={()=>continueModalHandler("invision")} clientAccess={clientAccess} id={id} trialInfo={trialInfo}/></div>
                    <div className="MainPage_company"><VCC id={id}/></div>
                </div>
                <div className="trial_info"><button className='read-more_btn' onClick={modalHandler}>Узнать о бесплатных консультациях</button></div>
            </div>
            
            <div className="footer"><ITSGContact/></div>
        </div>
    )

    const modal = (
        <div>
            <div className='ModalWindow__Trial__Click_Background' style={infoModal ? openStyle : null}>
                <div className='ModalWindow__Trial_Info'>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text Bold__Text'>Механика бесплатной консультации</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text'>Есть возможность получить по 1 консультации во всех сервисах, на которые нет подписки. Для этого откройте нужный сервис и оставьте запрос на бесплатную консультацию. </p>
                    </div>
                    <p className='ModalWindow__Info_Item__Text'>На текущий момент: </p>
                    <Trials clientAccess={clientAccess} trialInfo={trialInfo} />
                    <div className='ModalWindow__Info_Item'>
                        <button className='ModalWindow__Info_Item__Btn' onClick={modalHandler}>Понятно</button>
                    </div>
                </div>
            </div>
        </div>
    );

    const continueWorkModal = (
        <div>
            <div className='ModalWindow__Trial__Click_Background' style={continueModal ? openStyle : null}>
                <div className='ModalWindow__Trial_Info'>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text Bold__Text'>Бесплатные консультации закончились</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text'>Если вы хотите продолжить пользоваться услугами этого сервиса, оставьте заявку и наш менеджер свяжется с вами в ближайшее время.</p>
                    </div>
                    <div className='ModalWindow__Info_Item continue_buttons'>
                        <button className='ModalWindow__Info_Item__Btn' onClick={closeModals}>Назад</button>
                        <button className='ModalWindow__Info_Item__Btn new_request' onClick={async ()=> await postApplicationToContinue()}>Оставить заявку</button>
                    </div>
                </div>
            </div>
        </div>
    );

    const confirmModalBlock = (
        <div>
            <div className='ModalWindow__Trial__Click_Background' style={confirmModal ? openStyle : null}>
                <div className='ModalWindow__Trial_Info'>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text Bold__Text'>Ваш запрос принят</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text'>Наш менеджер свяжется с вами в ближайшее время.</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <button className='ModalWindow__Info_Item__Btn' onClick={closeModals}>На главную</button>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <TITLE title="Главная" icon="asista">
            {center}
            {infoModal ? modal : null}
            {continueModal ? continueWorkModal : null}
            {confirmModal ? confirmModalBlock : null}
        </TITLE>
    );
}

export default WithLoader(MainPage, axios);