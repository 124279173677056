import React, { useState, useEffect } from 'react';
import AuthenticationModal from '../../Components/UserLoginPageComponents/AuthenticationModal/AuthenticationModal';
import HelperInfo from '../../Components/UserLoginPageComponents/HelperInfo/HelperInfo';
import LoginForm from '../../Components/UserLoginPageComponents/LoginForm/LoginForm';
import MyPhoneInput from '../../Components/UserLoginPageComponents/PhoneInput/MyPhoneInput';
import SmsInput from '../../Components/UserLoginPageComponents/SmsInput/SmsInput';
import { useDispatch, useSelector } from "react-redux";
import { loginUser, apiSendSMS, sendSms, setLoginStatus } from "../../Store/UsersReducer/usersActions";
import axios from "../../axiosApi";
import WithLoader from '../../hoc/WithLoader/WithLoader';
import { clearMyInterval, forgetMe, saveId } from "../../Store/ApplicationsReducer/applicationsActions";
import PhoneInput from 'react-phone-input-2'
import TITLE from '../../Routes/TITLE';
import "./UserLoginPage.css"

const UserLoginPage = (props) => {

    const dispatch = useDispatch();

    const id = props.match.params.id;
    const timeUpdatingResend = 90;

    const phoneError = useSelector(state => state.users.phoneLoginError);
    const smsError = useSelector(state => state.users.smsLoginError);

    const [time, setTime] = useState(timeUpdatingResend);
    const [alreadySended, setAlreadySended] = useState(false);
    const [phone, setPhone] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [sms1, setSms1] = useState("");
    const [sms2, setSms2] = useState("");
    const [sms3, setSms3] = useState("");
    const [sms4, setSms4] = useState("");

    const allowResend = () => setAlreadySended(false);
    const disallowResend = () => setAlreadySended(true);

    const sendToWhatsapp = async () => {
        await dispatch(apiSendSMS({ phone: "+" + phone, to: 'whatsapp' }, id));
        disallowResend()
    }
    const sendPhoneHandler = async () => {
        await dispatch(apiSendSMS({ phone: "+" + phone, to: 'phone' }, id));
        disallowResend()
    }

    useEffect(() => {
        if (sms1 && sms2 && sms3 && sms4) {
            dispatch({ type: "SMS_LOGIN_ERROR", value: "" });
            disallowResend();
            const smsAndIdNumber = {
                device_id: id,
                code: `${sms1}${sms2}${sms3}${sms4}`
            }
            dispatch(sendSms(smsAndIdNumber));
        }
    }, [dispatch, id, sms1, sms2, sms3, sms4]);

    useEffect(() => {
        dispatch(clearMyInterval())
        if (id) {
            dispatch(saveId(id))
            dispatch(setLoginStatus("phone"));
        }
    }, [dispatch, id]);

    const sendPhoneByPressEnter = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await sendPhoneHandler()
        }
    }

    const resendSmsHandler = async () => {
        await sendPhoneHandler();
        setSms1('');
        setSms2('');
        setSms3('');
        setSms4('');
    }

    const toggleShowPassword = () => setShowPassword(!showPassword)

    const status = useSelector(state => state.users.loginStatus);
    let grey = false;
    let loginContent;
    let question;
    let link;
    let textLink;
    let contacts;


    const handleChangeSms = (e) => {
        const { maxLength, value, name } = e.target;
        const [, fieldIndex] = name.split("-");
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 4) {
                const nextSibling = document.querySelector(
                    `input[name=smsn-${parseInt(fieldIndex, 10) + 1}]`
                );

                if (nextSibling !== null) {
                    nextSibling.focus();

                }
            }
        }
        if (value.length === 0) {
            const prevSibling = document.querySelector(
                `input[name=smsn-${parseInt(fieldIndex, 10) - 1}]`
            );

            if (prevSibling !== null) {
                prevSibling.focus();
            }
        }
        if (name === "smsn-1") {
            setSms1(e.target.value);
        } else if (name === "smsn-2") {
            setSms2(e.target.value);
        } else if (name === "smsn-3") {
            setSms3(e.target.value);
        } else if (name === "smsn-4") {
            setSms4(e.target.value);
        }
    }

    if (status === "sms") {
        grey = true;
        question = "У вас возникли сложности?";
        link = "https://wa.me/77473901112";
        textLink = "";
        contacts = "Позвоните на +7 727 390 11 12, и мы что-нибудь придумаем.";
        loginContent = (
            <SmsInput
                timeResend={timeUpdatingResend}
                time={time}
                setTime={setTime}
                alreadySended={alreadySended}
                wrongPassword={smsError === "denied"}
                error={smsError}
                buttonName={"запросить SMS повторно"}
                buttonWidth={"303"}
                phone={`+7 ${phone.slice(1, 4)} *** ** ${phone.slice(9, phone.length)} `}
                clicked={(event) => { resendSmsHandler(event) }}
                sendSmsWhatsapp={sendToWhatsapp}
                sendSmsPhone={sendPhoneHandler}
                allowResend={allowResend}
            >
                <div className="SmsInput__phoneBlock">
                    <input
                        value={sms1}
                        placeholder="_"
                        className="SmsInput__inputPiece"
                        type="text"
                        name="smsn-1"
                        autoComplete='off'
                        maxLength={1}
                        onChange={handleChangeSms} />
                    <input
                        value={sms2}
                        placeholder="_"
                        className="SmsInput__inputPiece"
                        type="text"
                        name="smsn-2"
                        autoComplete='off'
                        maxLength={1}
                        onChange={handleChangeSms} />
                    <input
                        value={sms3}
                        placeholder="_"
                        className="SmsInput__inputPiece"
                        type="text"
                        name="smsn-3"
                        autoComplete='off'
                        maxLength={1}
                        onChange={handleChangeSms} />
                    <input
                        value={sms4}
                        placeholder="_"
                        className="SmsInput__inputPiece"
                        type="text"
                        name="smsn-4"
                        autoComplete='off'
                        maxLength={1}
                        onChange={handleChangeSms} />
                </div>
            </SmsInput>
        )
    }
    const inputPhoneValue = (event) => {
        if (!phone.length) {
            setPhone("7" + event)
        } else {
            setPhone(event)
        }
    }
    if (status === "phone") {
        grey = true;
        question = "У вас возникли сложности?";
        link = "https://wa.me/77473901112";
        textLink = "";
        contacts = "Позвоните на +7 727 390 11 12, и мы что-нибудь придумаем.";
        loginContent = (
            <MyPhoneInput
                error={phoneError === "denied"}
                buttonName={"запросить пароль"}
                buttonWidth={"303"}
                sendToPhone={sendPhoneHandler}
                sendToWhatsapp={sendToWhatsapp}
                disabled={phone.length !== 11}
            >
                <PhoneInput
                    onKeyDown={(event) => { sendPhoneByPressEnter(event) }}
                    placeholder='+7 (___) __-__-__'
                    masks={{ kz: '(...) ..-..-..' }}
                    value={phone}
                    onChange={(event) => { inputPhoneValue(event) }}
                    inputProps={{
                        required: true,
                        autoFocus: true
                    }}
                    alwaysDefaultMask={false}
                />
            </MyPhoneInput>
        )
    }

    const [user, setUser] = useState({
        username: "",
        password: "",
        remember: true
    });
    const inputValue = (event) => {
        const { name, value } = event.target;
        setUser(prevState => {
            return { ...prevState, [name]: value }
        });
    }
    const checkboxChange = () => {
        setUser(prevState => {
            return { ...prevState, remember: !user.remember }
        });
    }
    const submitLoginUser = (event) => {
        event.preventDefault();
        if (!user.remember) {
            dispatch(forgetMe())
        }
        dispatch(loginUser(user.username, user.password));
    }

    const submitLoginByEnterButton = (event) => {
        // Number 13 is the "Enter" key on the keyboard
        if (event.key === 'Enter') {
            event.preventDefault();

            dispatch(loginUser(user.username, user.password));

        }
    };

    const usernameError = useSelector(state => state.users.usernameLoginError);
    const passwordError = useSelector(state => state.users.passwordLoginError);

    if (status === "login") {
        question = "Не помните данные для входа?";
        link = "https://wa.me/77473901112";
        textLink = "";
        contacts = "Позвоните на +7 727 390 11 12, и мы восстановим ваш логин и пароль.";
        loginContent = (
            <LoginForm
                title={"Личный кабинет клиента"}
                keyPress={(event) => { submitLoginByEnterButton(event) }}
                submit={(event) => { submitLoginUser(event) }}
                loginOnChange={(event) => { inputValue(event) }}
                passwordOnChange={(event) => { inputValue(event) }}
                checkOnChange={checkboxChange}
                checkboxValue={user.remember}
                errorUsername={usernameError}
                errorPassword={passwordError}
                buttonName={"войти"}
                buttonWidth={"142"}
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
            />
        )
    }

    return (
        <TITLE title="Логин" icon="">

            <AuthenticationModal>
                {loginContent}
            </AuthenticationModal>
            <HelperInfo
                question={question}
                link={link}
                textLink={textLink}
                contacts={contacts}
                grey={grey}
                instagram={false}
                facebook={false}
                linkedin={false}
                youtube={false}
            />
        </TITLE>
    )
}

export default WithLoader(UserLoginPage, axios);
