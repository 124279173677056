export const CONFIG = {
	usersWebApi: process.env.REACT_APP_USERS_WEB_API,
	apiUrl: process.env.REACT_APP_API_URL,
	appDomain:process.env.REACT_APP_APP_DOMAIN,
	biBack: process.env.REACT_APP_BIBACK_DOMAIN,
	chatApi: process.env.REACT_APP_CHAT_API,
	publicVapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
	accumulationApiUrl: process.env.REACT_APP_ACCUMULATION_API_URL,
	accumuluationReserveApiUrl: process.env.REACT_APP_ACCUMULATION_RESERVE_API_URL,
	webCrmUrl: process.env.REACT_APP_WEB_CRM_API_URL,
}
